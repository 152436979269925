<template>

	<div class="pageContainer app-container">
		<el-row class="">
			<el-select v-model="searchForm.ktype" size="small" placeholder="请选择"
				style="width: 150px; margin-right: 10px;">
				<el-option label="学生姓名" value="stu_name">学生姓名</el-option>
				<el-option label="手机号" value="phone">手机号</el-option>
			</el-select>
			<el-input v-model="searchForm.keyword" size="small" placeholder="请输入"
				style="max-width: 200px;margin-right: 10px" />
				
			<el-select v-model="searchForm.course_id" size="small" placeholder="请选择"
				style="width: 150px; margin-right: 10px;">
				<el-option label="全部" value="">全部</el-option>
				<el-option v-for="(item,idx) in courseList" :key="idx" :label="item.course_name" :value="item.id"></el-option>
				
			</el-select>
				
			<el-button size="small" icon="el-icon-search" @click="onSearch" plain type="primary">查询</el-button>
			
			<el-button size="small" icon="el-icon-plus" @click="formAdd" type="primary">添加学生</el-button>
			
			<el-upload style="display: inline-block; margin-right: 20px; margin-left: 10px" action=""
					:http-request="uploadTemp" :on-success="uploadFiles" :show-file-list="false"
					name="image">
				<el-button size="small" icon="el-icon-upload2" type="primary">导入学生表</el-button>
			</el-upload>
			
			<el-button size="small" icon="el-icon-download" type="text">
					<a href="/xls/student_temp.xlsx" target="_blank"
						style="text-decoration: none; color: inherit; margin-right: 20px;">下载导入模板</a>
			</el-button>
		</el-row>
		<el-row type="flex" style="margin: 20px 0;" justify="start">
			<el-tabs v-model="activeName" type="border-card" style="width: 100%">
				<el-tab-pane v-for="item in tabMapOptions" :key="item.key" :label="item.label" :name="item.key">
					<el-table :data="tableData" style="width: 100%;margin-bottom: 20px;" border stripe>
						
						<el-table-column prop="index" width="55" label="序号">
							<template slot-scope="scope">
								{{scope.$index+1+((page.pageIndex-1)*page.pageSize)}}
							</template>
						</el-table-column>
						<el-table-column prop="effective" label="头像" width="120">
							<template slot-scope="scope">
								<img v-if="scope.row.avatar" :src="scope.row.avatar" style="width: 30px; height: 30px;" />
							</template>
						</el-table-column>
						<el-table-column prop="effective" label="昵称(点击解绑)" width="120">
							<template slot-scope="scope">
								<span @click="unbindOpenid(scope.row.id)">{{scope.row.nickname}}</span>
							</template>
						</el-table-column>
						<el-table-column prop="stu_name" label="学生姓名" width="150">
							
						</el-table-column>
						<el-table-column prop="stu_code" label="学号" width="150">
							
						</el-table-column>
						<el-table-column prop="class_name" label="班级" width="150">
							
						</el-table-column>
					
						
						<el-table-column prop="phone" label="手机号" width="160"></el-table-column>
						
						<el-table-column prop="effective" label="课程" min-width="120">
							<template slot-scope="scope">
								<span style="margin: 0 10px 10px 0px; display: inline-block;" v-for="(item,idx ) in scope.row.course" :key="idx">{{item.course_name}}</span>
							</template>
						</el-table-column>
						
						
						


						<el-table-column fixed="right" label="操作" width="160">
							<template slot-scope="scope">
								<el-popconfirm v-if="!scope.row.locked" width="250" confirmButtonText='确定'
									cancelButtonText='取消' icon="el-icon-info" iconColor="red"
									@confirm="handleDel(scope.row)" placement="left" title="是否确定删除？">
									<a slot="reference" size="small"
										style="color: red;font-size: 12px;margin-right: 10px; cursor: pointer;">删除</a>
								</el-popconfirm>
								<el-button v-if="!scope.row.locked" @click="handleModify(scope.row)" type="text"
									size="small">修改</el-button>
								<el-button v-if="!scope.row.locked" @click="resetPwd(scope.row)" type="text"
									size="small">重置密码</el-button>
							</template>
						</el-table-column>
					</el-table>
					<el-pagination @current-change="handlePageChange" :current-page="page.pageIndex"
						:page-size="page.pageSize" layout="total, prev, pager, next, jumper" :total="page.total">
					</el-pagination>

				</el-tab-pane>
			</el-tabs>
		</el-row>
		<el-dialog :title="modalTitle" :visible.sync="isShowModal" :close-on-click-modal="false"
			:destroy-on-close="true">
			<el-form :model="formData" :rules="rules" ref="adminUserInfo" label-width="120px"
				style="max-height: 450px;overflow-y: auto;min-height: 300px;">
				<el-form-item label="姓名" prop="stu_name">
					<el-input v-model.trim="formData.stu_name" style="width: 50%" />
				</el-form-item>
				<el-form-item label="手机号" prop="phone">
					<el-input v-model.trim="formData.phone" style="width: 50%" />
					<div  style="font-size: 12px; color: orangered;">
						默认密码为手机号后6位
					</div>
				</el-form-item>
				
			</el-form>
			<el-row>
				<el-col :span="22" style="text-align: right;">
					<el-button size="small" type="default" @click="isShowModal = false">取消</el-button>
					<el-button size="small" type="primary" @click="submitForm('adminUserInfo')">保存</el-button>
				</el-col>
			</el-row>
		</el-dialog>


		<el-dialog title="选择角色" :visible.sync="isShowRole" :close-on-click-modal="false" :destroy-on-close="true"
			width="400px" top="60px">
			<!-- <div style="text-align: right; margin-bottom: 10px; border-top: 1px solid #ccc; position: relative;">
				<div style="position: absolute; top: -40px; right: 0;">
					<el-button type="primary" icon="el-icon-s-claim" size="small" @click="saveRole">保存</el-button>
				</div>
			</div> -->
			<el-tree ref="authTree" :props=" {children: 'children',label: 'role_name'}" node-key="id" :data="authData"
				:default-expand-all="true" show-checkbox>

			</el-tree>

			<div style="text-align: right; padding: 10px; margin-top: 10px; border-top: 1px solid #ccc;">
				<el-button type="primary" icon="el-icon-s-claim" size="small" @click="saveRole">保存</el-button>
			</div>
		</el-dialog>


		<el-dialog title="导入学生" :visible.sync="updDialog" :close-on-click-modal="false" :destroy-on-close="true"
			width="400px" top="60px">
			<div style="text-align: center; color: orangered; padding-bottom: 20px;">请确保教师手机号格式正确！</div>
			<div style="text-align: center; color: orangered; padding-bottom: 50px;">登录密码为手机号后六位，<br /> 若没填写手机号，则会导入失败</div>
			<div style="text-align: right;">
				<el-upload style="display: inline-block; margin-right: 20px;" action="" :http-request="uploadTemp"
					:on-success="uploadFiles" :show-file-list="false" name="image">
					<el-button size="small" icon="el-icon-upload2" type="primary">选择文件</el-button>
				</el-upload>
			</div>
			

		</el-dialog>


	</div>
</template>
<script>
	import api from '@/api/api';

	export default {
		name: 'stuDept',
		data() {
			var checkPhone = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('手机号不能为空'));
				} else {
					const reg = /^1[3|4|5|7|8][0-9]\d{8}$/

					if (reg.test(value)) {
						callback();
					} else {
						return callback(new Error('请输入正确的手机号'));
					}
				}
			};
			return {
				searchForm: {
					ktype: 'stu_name',
					course_id:""
				}, //查询
				//dialog
				visible: false,
				isShowModal: false,
				isShowRole: false,
				updDialog: false,
				modalTitle: '添加',
				formData: {},
				firstCascaderProps: {
					label: 'dept_name',
					value: 'id',
					children: 'children',
					emitPath: false,
					multiple: true,
					checkStrictly: true
				},
				rules: {
					stu_name: [{
						required: true,
						message: '请输入姓名',
						trigger: 'blur'
					}, ],
					
					phone: [{
						required: true,
						validator: checkPhone,
						trigger: 'blur'
					}],
				},
				//tree
				treeData: [],
				treeProps: {
					children: 'children',
					label: 'dept_name'
				},
				//table
				tabMapOptions: [{
					label: '学生列表',
					key: 'CN'
				}, ],
				activeName: 'CN',
				tableData: [],
				page: {
					pageIndex: 1,
					pageSize: 20,
					total: 0
				},
				multipleSelection: [],
				deptId: null,
				tchId: null,
				authData: [],
				courseList:[]

			}
		},
		watch: {
			activeName(val) {
				this.$router.push(`${this.$route.path}?tab=${val}`)
			}
		},
		created() {
			// init the default selected tab
			const tab = this.$route.query.tab
			if (tab) {
				this.activeName = tab
			}
		},
		mounted() {
			this.getCourse()
			this.getList();
			
		},
		methods: {
			getCourse(){
				
				this.$http.post("/api/t_sch_course_list", {pagesize:100}).then(res => {
					this.courseList = res.data.data
				})
				this.$http.post("/api/get_teacher_course").then(res => {
					if(res.data.length>0){
						this.searchForm.course_id = res.data[0].id
					}
				})
			},
			uploadFiles(e) {
				
				this.$http.post("/api/import_students", {
					url: e.src
				}).then(res => {
					this.$message.success("导入成功")
					if (res.data.msg.length > 0) {
						let html = ''
						for (let msg of res.data.msg) {
							html += "<div style='font-size:12px'>" +msg + "</div>"
						}
						this.$alert(html, '错误的数据', {
							dangerouslyUseHTMLString: true
						});
						
						
					}
					this.getList();
					this.updDialog = false


				})
			},
			
			//table
			getList() {
				let params = {
					page: this.page.pageIndex,
					pagesize: this.page.pageSize,
					ktype: this.searchForm.ktype,
					keyword: this.searchForm.keyword,
					course_id:this.searchForm.course_id
				}
				this.$http.post("/api/t_sch_students_list", params).then(res => {
					this.tableData = res.data.data;
					this.page.total = res.data.page.count;
				})
			},
			formAdd() {
				this.isShowModal = true;
				this.formData = {}
				this.modalTitle = '添加'
			},
			handleAdd(row) {
				this.isShowModal = true;
				this.formData = {}
				this.modalTitle = '添加'
			},
			handleModify(row) {
				this.isShowModal = true;
				this.modalTitle = '修改';
				let ids = []
				if (row.dept && row.dept.length > 0) {
					ids = row.dept.map((item, index) => {
						return item.dept_id
					})
					row.dept_id = ids
				}
				this.formData = row
			},
			handleDel(row) {
				this.$http.post("/api/t_sch_students_delete", {
					id: row.id
				}).then(res => {
					this.$message.success('删除成功')
					this.getList();
				})
			},
			handlePageChange(e) {
				this.page.pageIndex = e
				this.getList()
			},
			handleCascaderChange(e) {
				console.log(1, e)
			},
			unbindOpenid(id){
				this.$confirm('确定解绑?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.post("/api/students_untie", {
						id: id
					}).then(res => {
						this.$message({
							type: 'success',
							message: '解绑成功'
						});
						this.getList()
				
					})
				
				}).catch(() => {
				
				});
			},
			submitForm(formName) {
				let _this = this
				_this.$refs[formName].validate((valid) => {
					if (valid) {
						let params = {
							..._this.formData
						}
					
						_this.$http.post("/api/t_sch_students_edit", params).then(res => {
							if (res.data.code == 500) {
								this.$message.warning(res.data.msg ? res.data.msg : '保存失败')
							} else if (res.data.code) {
								if (res.data.sqlMessage && res.data.sqlMessage.indexOf(
										"Duplicate entry") >= 0) {
									this.$message.error('保存失败，手机号已存在，请更换手机号！')
									this.getList();
								}
							} else {
								this.$message.success('保存成功')
								this.getList();
								this.isShowModal = false;
							}

						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			onSearch() {
				this.tableData = [];
				this.page.pageIndex = 1;
				this.getList();
			},
			onReset() {
				this.searchForm = {
					ktype: 'username'
				};
				this.tableData = [];
				this.page.pageIndex = 1;
				this.getList();
			},
			handleSelectionChange(val) {
				let _this = this;
				_this.multipleSelection = val;
			},
			mutiDel() {
				let ids = []
				if (this.multipleSelection && this.multipleSelection.length > 0) {
					ids = this.multipleSelection.map((item, index) => {
						return item.id
					}).join(',')
					this.handleDel({
						id: ids
					})
				} else {
					this.$message.warning('请至少选择一条数据')
				}
			},
			resetPwd(item) {
				this.$confirm('重置密码为手机号后六位，确定重置?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.post("/api/update_stu_passWord", {
						stu_id: item.id
					}).then(res => {
						this.$message({
							type: 'success',
							message: '重置成功'
						});


					})

				}).catch(() => {

				});
			},
			setAuth() {

				this.tchId = null
				if (this.multipleSelection <= 0) {
					this.$message.error("请选择教师")
					return
				}
				this.isShowRole = true
			},
			saveRole() {
				let role_id = this.$refs["authTree"].getCheckedNodes(false, true).map(e => {
					return e.id
				}).join(",")
				if (!role_id) {
					this.$message.error("请选择角色")
					return
				}
				if (this.tchId) {
					this.$http.post("/api/set_teacher_role", {
						teacher_id: this.tchId,
						role_id: role_id
					}).then(ok => {
						this.$message.success("设置成功")
						this.isShowRole = false
						this.getList()
					})
				} else {
					this.$http.post("/api/set_teacher_role", {
						teacher_id: this.multipleSelection.map(e => {
							return e.id
						}).join(","),
						role_id: role_id
					}).then(ok => {
						this.$message.success("设置成功")
						this.isShowRole = false
						this.getList()
					})
				}

			},
			showOneTeacher(t) {
				this.tchId = t.id;
				this.isShowRole = true
				this.$nextTick(() => {
					this.$refs["authTree"].setCheckedKeys(t.roles.map(e => {
						return e.role_id
					}))
				})
			}
		}
	}
</script>

<style scoped lang="less" type="text/less">
	.app-container {
        .treeContainer {
            height: 100%;
            background-color: #fff;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .12), 0 0 6px 0 rgba(0, 0, 0, .04);
            border: 1px solid rgba(220, 223, 230, 0.6);
            
            .treeTitle {
                height: 40px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                color: #409eff;
                padding-left: 6px;
            }
        }
		.roleitem{
			display: inline-block; padding: 1px 5px;
		font-size: 12px;background-color:#00B0E8;
		color:#fff;
		 margin-right:5px;
		 margin-bottom: 5px;
		 border-radius: 2px;
		}
    }
</style>
